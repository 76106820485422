/**
 * High number of iterations.
 * This is used for payload encryption (in transit)
 */
const InTransitpbkdf2Iterations = 100000;

/**
 * Really really number of iterations.
 * This is used for sensitive information encryption such as encryption keys, records,
 */
const AtRestpbkdf2Iterations = 500000;

class EncryptionService {
  /**
   * Encodes a utf8 string as a byte array.
   * @param {String} str
   * @returns {Uint8Array}
   */
  str2buf(str: string): Uint8Array {
    return new TextEncoder().encode(str);
  }

  /**
   * Decodes a byte array as a utf8 string.
   * @param {Uint8Array} buffer
   * @returns {String}
   */
  buf2str(buffer: Uint8Array): string {
    return new TextDecoder("utf-8").decode(buffer);
  }

  /**
   * Decodes a string of hex to a byte array.
   * @param {String} hexStr
   * @returns {Uint8Array}
   */
  hex2buf(hexStr: string): Uint8Array {
    return new Uint8Array(hexStr.match(/.{2}/g).map((h) => parseInt(h, 16)));
  }

  /**
   * Encodes a byte array as a string of hex.
   * @param {Uint8Array} buffer
   * @returns {String}
   */
  buf2hex(buffer: Uint8Array): string {
    return Array.prototype.slice
      .call(new Uint8Array(buffer))
      .map((x: number) => [x >> 4, x & 15])
      .map((ab: any[]) => ab.map((x) => x.toString(16)).join(""))
      .join("");
  }

  /**
   * Given a passphrase, this generates a crypto key
   * using `PBKDF2` with SHA256 and N iterations.
   * If no salt is given, a new one is generated.
   * The return value is an array of `[key, salt]`.
   * @param {String} passphrase
   * @param {String} type
   * @param {UInt8Array} salt [salt=random bytes]
   * @returns {Promise<[CryptoKey,UInt8Array]>}
   */
  async deriveKey(
    passphrase: string,
    type: string,
    salt: Uint8Array
  ): Promise<[CryptoKey, Uint8Array]> {
    salt = salt || window.crypto.getRandomValues(new Uint8Array(8));
    const numberOfIterations =
      type == "payload" ? InTransitpbkdf2Iterations : AtRestpbkdf2Iterations;
    const key = await window.crypto.subtle.importKey(
      "raw",
      this.str2buf(passphrase),
      "PBKDF2",
      false,
      ["deriveKey"]
    );
    const derivedKey = await window.crypto.subtle.deriveKey(
      { name: "PBKDF2", salt, iterations: numberOfIterations, hash: "SHA-256" },
      key,
      { name: "AES-GCM", length: 256 },
      false,
      ["encrypt", "decrypt"]
    );
    return [derivedKey, salt];
  }

  /**
   * Given a passphrase and some plaintext, this derives a key
   * (generating a new salt), and then encrypts the plaintext with the derived
   * key using AES-GCM. The ciphertext, salt, and iv are hex encoded and joined
   * by a "-". So the result is `"salt-iv-ciphertext"`.
   * @param {String} passphrase
   * @param {String} plaintext
   * @param {String} type
   * @returns {Promise<String>}
   */
  async encrypt(
    passphrase: string,
    plaintext: string,
    type: string
  ): Promise<string> {
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const data = this.str2buf(plaintext);
    const [key, salt] = await this.deriveKey(passphrase, type, null);
    const ciphertext = await window.crypto.subtle.encrypt(
      { name: "AES-GCM", iv },
      key,
      data
    );
    return `${this.buf2hex(salt)}-${this.buf2hex(iv)}-${this.buf2hex(
      new Uint8Array(ciphertext)
    )}`;
  }
  /**
   * Given a key and ciphertext (in the form of a string) as given by `encrypt`,
   * this decrypts the ciphertext and returns the original plaintext
   * @param {String} passphrase
   * @param {String} saltIvCipherHex
   * @param {String} type
   * @returns {Promise<String>}
   */
  async decrypt(
    passphrase: string,
    saltIvCipherHex: string,
    type: string
  ): Promise<string> {
    const [salt, iv, data] = saltIvCipherHex.split("-").map(this.hex2buf);
    const [key] = await this.deriveKey(passphrase, type, salt);
    const v = await window.crypto.subtle.decrypt(
      { name: "AES-GCM", iv },
      key,
      data
    );
    return this.buf2str(new Uint8Array(v));
  }

  /**
   * Generate cryptographically secure symmetric key to be used as encryption key
   * @returns {Promise<String>}
   */
  async generateEncryptionKey(): Promise<string> {
    const encKey = await window.crypto.subtle.generateKey(
      {
        name: "AES-GCM",
        length: 256,
      },
      true,
      ["encrypt", "decrypt"]
    );
    const extKey = await crypto.subtle.exportKey("jwk", encKey);
    return extKey.k;
  }
}

export default new EncryptionService();
