import CompanyService from "@/services/company.service";

const userData = JSON.parse(sessionStorage.getItem("user"));
const initialState = userData ? userData : null;

export const company = {
  namespaced: true,
  state: initialState,
  actions: {
    current({ commit }) {
      return CompanyService.getCurrentCompany().then(
        (company) => {
          commit("currentCompanySuccess", company);
          return Promise.resolve(company);
        },
        (error) => {
          commit("currentCompanyFailure");
          return Promise.reject(error);
        }
      );
    },
    switch({ commit }, data) {
      return CompanyService.changeCompany(data).then(
        (company) => {
          commit("switchCompanySuccess", company);
          return Promise.resolve(company);
        },
        (error) => {
          commit("switchCompanyFailure");
          return Promise.reject(error);
        }
      );
    },
    defaults({ commit }) {
      return CompanyService.getCompanyDefaults().then(
        (company) => {
          commit("companyDefaultsSuccess", company);
          return Promise.resolve(company);
        },
        (error) => {
          commit("companyDefaultsFailure");
          return Promise.reject(error);
        }
      );
    },
    create({ commit }, companyData) {
      return CompanyService.createCompany(companyData).then(
        (company) => {
          commit("createCompanySuccess", company);
          return Promise.resolve(company);
        },
        (error) => {
          commit("createCompanyFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    companyDefaultsSuccess(state, companyData) {
      state.company = null;
      state.company = companyData;
    },
    companyDefaultsFailure(state) {
      state.company = null;
    },
    currentCompanySuccess(state, companyData) {
      state.company = companyData;
    },
    currentCompanyFailure(state) {
      state.company = null;
    },
    switchCompanySuccess(state, data) {
      state.company = data;
    },
    switchCompanyFailure(state) {
      state.company = null;
    },
    createCompanySuccess(state, companyData) {
      state.company = null;
      state.company = companyData;
    },
    createCompanyFailure(state) {
      state.company = null;
    },
  },
};
