const {
  createVNode: _createVNode,
  openBlock: _openBlock,
  createBlock: _createBlock,
} = require("vue");

module.exports = function render(_ctx, _cache) {
  return (
    _openBlock(),
    _createBlock(
      "svg",
      {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "none",
        viewBox: "0 0 24 24",
        "stroke-width": "1",
        stroke: "currentColor",
        "aria-hidden": "true",
      },
      [
        // _createVNode("path", {
        //   d: "M12.0001 3a12 12 0 0 0 8.5 3 11.9992 11.9992 0 0 1-1.0726 9.3005A11.9988 11.9988 0 0 1 12.0001 21a12 12 0 0 1-8.5-15A12 12 0 0 0 12 3",
        // }),
        // _createVNode("path", {
        //   d: "M6 9.25h12m-11 3h10m-9 3h8",
        // }),

        _createVNode("path", {
          d: "M12.0001 3a12 12 0 0 0 8.5 3 11.9992 11.9992 0 0 1-1.0726 9.3005A11.9988 11.9988 0 0 1 12.0001 21a12 12 0 0 1-8.5-15A12 12 0 0 0 12 3",
        }),
        _createVNode("path", {
          d: "M12 11.5c1.1046 0 2-.8954 2-2s-.8954-2-2-2-2 .8954-2 2 .8954 2 2 2Zm-3 5v-1a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v1",
        }),
      ]
    )
  );
};
