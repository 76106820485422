import UserService from "@/services/user.service";

const userData = JSON.parse(sessionStorage.getItem("auth"));
const initialState = userData ? userData.user : null;

export const user = {
  namespaced: true,
  state: initialState,
  actions: {
    details({ commit }) {
      return UserService.getUserDetails().then(
        (user) => {
          commit("userDetailsSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("userDetailsFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    userDetailsSuccess() {
      //state.auth.user = user;
    },
    userDetailsFailure(state) {
      state.auth.user = null;
    },
  },
};
