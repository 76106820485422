<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div class="overflow-hidden sm:rounded-lg">
          <div class="border-b border-gray-200 pb-5">
            <div class="sm:flex sm:items-baseline sm:justify-between">
              <div class="ml-4 mt-4">
                <h3
                  class="text-lg font-medium leading-6 text-passwordlyGray-500"
                >
                  Roles
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  Manage your company roles.
                </p>
              </div>
              <div class="ml-4 mt-4 mr-1 flex-shrink-0">
                <button
                  v-tippy="{ content: 'Add new role', theme: 'material' }"
                  type="button"
                  class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-xs font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
                >
                  <PlusSmIcon
                    class="h-5 w-5 text-passwordlyGreen-400"
                    aria-hidden="true"
                  />
                  Add Role
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="loading"
            class="flex w-full min-w-full items-center justify-center py-10"
          >
            <div class="text-center">
              <svg
                class="text-teal-600"
                width="57"
                height="57"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                enable-background="new 0 0 0 0"
                xml:space="preserve"
              >
                <path
                  class="opacity-50"
                  fill="currentColor"
                  d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                >
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    dur="1s"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite"
                  />
                </path>
              </svg>
            </div>
          </div>

          <table v-if="!loading" class="min-w-full divide-y divide-gray-200">
            <thead class="">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Role
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Users
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Status
                </th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody class="relative divide-y divide-gray-200">
              <div v-if="!loading && !roles.length">
                <td class="whitespace-nowrap px-6 py-4">No roles found</td>
              </div>

              <tr v-for="role in roles" :key="role.id">
                <td class="whitespace-nowrap px-6 py-4">
                  <div class="flex items-center">
                    <div class="text-sm text-gray-500">
                      {{ role.name }}
                    </div>
                  </div>
                </td>
                <td class="whitespace-nowrap px-6 py-4">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm text-gray-500">
                        {{ role.users }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  <div class="flex items-center justify-center">
                    <SwitchGroup
                      as="div"
                      class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5"
                    >
                      <dd
                        class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                      >
                        <Switch
                          :disabled="role.users.length"
                          v-tippy="{
                            content: role.isActive ? 'Active' : 'Inactive',
                            theme: 'material',
                          }"
                          v-model="role.isActive"
                          :class="[
                            role.isActive
                              ? 'bg-passwordlyGreen-500'
                              : 'bg-passwordlyGray-200',
                            'relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-passwordlyGreen-500 focus:ring-offset-2 sm:ml-auto',
                          ]"
                        >
                          <span class="sr-only">Use setting</span>
                          <span
                            :class="[
                              role.isActive ? 'translate-x-5' : 'translate-x-0',
                              'pointer-events-none relative inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                            ]"
                          >
                            <span
                              :class="[
                                role.isActive
                                  ? 'opacity-0 duration-100 ease-out'
                                  : 'opacity-100 duration-200 ease-in',
                                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                              ]"
                              aria-hidden="true"
                            >
                              <svg
                                class="h-3 w-3 text-passwordlyGray-400"
                                fill="none"
                                viewBox="0 0 12 12"
                              >
                                <path
                                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span>
                            <span
                              :class="[
                                role.isActive
                                  ? 'opacity-100 duration-200 ease-in'
                                  : 'opacity-0 duration-100 ease-out',
                                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                              ]"
                              aria-hidden="true"
                            >
                              <svg
                                class="h-3 w-3 text-passwordlyGreen-600"
                                fill="currentColor"
                                viewBox="0 0 12 12"
                              >
                                <path
                                  d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                                />
                              </svg>
                            </span>
                          </span>
                        </Switch>
                      </dd>
                    </SwitchGroup>
                  </div>
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
                >
                  <button
                    v-tippy="{
                      content: 'Trust this device for 30 days',
                      theme: 'material',
                    }"
                    type="button"
                    class="inline-flex items-center rounded-md border border-gray-200 bg-white py-1 px-1 text-sm font-medium shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
                  >
                    <ShieldCheckIcon
                      class="h-5 w-5 text-teal-500"
                      aria-hidden="true"
                    />
                  </button>

                  <button
                    v-tippy="{
                      content: 'Delete this device',
                      theme: 'material',
                      font: '12',
                    }"
                    type="button"
                    class="ml-3 inline-flex items-center rounded-md border border-gray-200 bg-white py-1 px-1 text-sm font-medium shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
                  >
                    <TrashIcon
                      class="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalMessageService from "@/services/globalmessage.service";
import CompanyService from "@/services/company.service";
import { useToast } from "vue-toastification";

import { Switch, SwitchGroup } from "@headlessui/vue";

import { TrashIcon, PlusSmIcon, ShieldCheckIcon } from "@heroicons/vue/outline";

export default {
  name: "UserRolesComp",
  components: {
    Switch,
    SwitchGroup,
    ShieldCheckIcon,
    TrashIcon,
    PlusSmIcon,
  },
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  data() {
    return {
      roles: [],
      loading: true,
      message: "",
    };
  },
  mounted() {
    CompanyService.getCompanyRoles().then(
      (response) => {
        this.roles = response.response.roles;
        this.loading = false;
      },
      (error) => {
        this.message = GlobalMessageService.parseError(error);
        this.toast.clear();
        this.toast.error(this.message.message, {
          position: "top-center",
          timeout: 5000,
        });
      }
    );
  },
  methods: {},
};
</script>
