import api from "./api";
import EncryptionService from "./cryptography/encryption.service";

class CompanyService {
  async getCurrentCompany() {
    const response: any = await api.get("company/current");
    return response.data;
  }

  async getCompanyDefaults() {
    const response: any = await api.get("company/defaults");
    return response.data;
  }

  async getCompanyRoles() {
    const response: any = await api.get("company/roles");
    return response.data;
  }

  async getCompanyGroups() {
    const response: any = await api.get("company/groups");
    return response.data;
  }

  async generateInviteKey(companyEncryptionKey: string) {
    const inviteEncryptionKey = await EncryptionService.generateEncryptionKey();
    const companyKeyEncrypted = await EncryptionService.encrypt(
      inviteEncryptionKey,
      companyEncryptionKey,
      "rest"
    );
    const payload = {
      token: inviteEncryptionKey,
      key: companyKeyEncrypted,
    };
    return payload;
  }

  async changeCompany(data: any) {
    const payload = await EncryptionService.encrypt(
      data.k,
      JSON.stringify({ id: data.id }),
      "payload"
    );

    const response: any = await api.post("company/switch", {
      p: payload,
    });
    return response.data;
  }

  async createCompany(data: any) {
    const companyLogo = data.company.logo;
    data.company.logo = "";
    const payload = await EncryptionService.encrypt(
      data.k,
      JSON.stringify(data.company),
      "payload"
    );

    const response: any = await api.put("company/create", {
      p: payload,
      logo: companyLogo,
    });
    return response.data;
  }
}

export default new CompanyService();
