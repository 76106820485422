<template>
  <form class="space-y-8">
    <div class="mt-10 divide-y divide-gray-200">
      <div class="space-y-1">
        <h3 class="text-lg font-medium leading-6 text-gray-900">Profile</h3>
        <p class="max-w-2xl text-sm text-gray-500">
          This information will be displayed publicly so be careful what you
          share.
        </p>
      </div>
      <div class="mt-6">
        <dl class="divide-y divide-gray-100">
          <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
            <dt class="text-sm font-medium text-gray-500">Email</dt>
            <dd class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <span class="flex-grow">{{ this.$store.state.user.email }}</span>
              <span class="ml-4 flex-shrink-0">
                <button
                  v-tippy="{ content: 'Change your email' }"
                  @click.prevent="showChangeEmail = true"
                  type="button"
                  class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-1 py-1 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-teal-600 focus:z-10 focus:border-teal-600 focus:outline-none focus:ring-1 focus:ring-teal-600"
                >
                  <span class="sr-only">Change</span>
                  <PencilIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </span>
            </dd>
          </div>
          <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt class="text-sm font-medium text-gray-500">Name</dt>
            <dd class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <span v-if="!userObj.fullName.editable" class="flex-grow">{{
                this.$store.state.user.name
              }}</span>

              <span v-if="userObj.fullName.editable" class="flex-grow">
                <input
                  type="text"
                  name="fullName"
                  id="fullName"
                  v-model="userObj.fullName.value"
                  class="block w-auto min-w-fit rounded-md border-gray-300 shadow-sm focus:border-teal-600 focus:ring-teal-600 sm:text-sm"
                  placeholder="Enter your name"
                />
              </span>

              <span class="ml-4 flex-shrink-0">
                <button
                  v-tippy="{ content: 'Change your name' }"
                  @click.prevent="
                    goEditMode(userObj.fullName, this.$store.state.user.name)
                  "
                  v-if="!userObj.fullName.editable"
                  type="button"
                  class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-1 py-1 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-teal-600 focus:z-10 focus:border-teal-600 focus:outline-none focus:ring-1 focus:ring-teal-600"
                >
                  <span class="sr-only">Change</span>
                  <PencilIcon class="h-5 w-5" aria-hidden="true" />
                </button>

                <span
                  v-if="userObj.fullName.editable"
                  class="relative z-0 inline-flex rounded-md shadow-sm"
                >
                  <button
                    type="button"
                    class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-1 py-1 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-teal-600 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-1 focus:ring-teal-500"
                  >
                    <span class="sr-only">Update</span>
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    @click.prevent="cancelEditMode(userObj.fullName)"
                    type="button"
                    class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-1 py-1 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-red-400 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-1 focus:ring-teal-500"
                  >
                    <span class="sr-only">Cancel</span>
                    <XIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </span>
              </span>
            </dd>
          </div>
          <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
            <dt class="text-sm font-medium text-gray-500">Password</dt>
            <dd class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <span class="flex-grow">*********</span>
              <span class="ml-4 flex-shrink-0">
                <button
                  v-tippy="{ content: 'Change your password' }"
                  @click.prevent="showChangePassword = true"
                  type="button"
                  class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-1 py-1 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-teal-600 focus:z-10 focus:border-teal-600 focus:outline-none focus:ring-1 focus:ring-teal-600"
                >
                  <span class="sr-only">Change</span>
                  <svg
                    class="h-5 w-5 text-gray-500 hover:text-teal-600"
                    fill="currentColor"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M747.776 397.632V203.776A139.84 139.84 0 0 0 607.936 64H418.112a139.776 139.776 0 0 0-139.776 139.776V397.44A127.616 127.616 0 0 0 160 524.48V832a128 128 0 0 0 128 128h448a128 128 0 0 0 128-128V524.48c0-66.752-51.2-120.832-116.224-126.848z m-175.68 321.344v92.48a34.88 34.88 0 0 1-34.88 35.008h-46.4a34.944 34.944 0 0 1-34.944-35.008v-92.48A112.896 112.896 0 0 1 398.72 621.44c0-62.656 51.648-113.536 115.264-113.536s115.264 50.816 115.264 113.536a112.448 112.448 0 0 1-57.152 97.536z m57.216-322.496H398.72V243.328c0-38.592 31.296-69.888 69.888-69.888h90.816c38.592 0 69.888 31.296 69.888 69.888v153.152z"
                    />
                  </svg>
                </button>
              </span>
            </dd>
          </div>
          <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
            <dt class="text-sm font-medium text-gray-500">Profile Image</dt>
            <dd class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <span class="flex-grow">
                <img
                  v-if="this.$store.state.user.image != ''"
                  class="h-8 w-8 rounded-md"
                  :src="this.$store.state.user.image"
                  alt=""
                />
                <span
                  v-if="this.$store.state.user.image == ''"
                  class="inline-flex h-8 w-8 items-center justify-center rounded-md bg-gradient-to-br from-passwordlyGreen-400 to-passwordlyGreen-700"
                >
                  <span
                    v-if="this.$store.state.user.image == ''"
                    class="text-sm font-medium leading-none text-white"
                    >{{
                      userInitials(this.$store.state.user.name.toUpperCase())
                    }}</span
                  >
                </span>
              </span>
              <span class="ml-4 flex flex-shrink-0 items-start space-x-4">
                <button
                  type="button"
                  class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-1 py-1 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-teal-600 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-1 focus:ring-teal-500"
                >
                  <span class="sr-only">Upload</span>
                  <CloudUploadIcon class="h-5 w-5" aria-hidden="true" />
                </button>

                <span
                  v-if="this.$store.state.user.image != ''"
                  class="relative z-0 inline-flex rounded-md shadow-sm"
                >
                  <button
                    type="button"
                    class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-1 py-1 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-teal-600 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-1 focus:ring-teal-500"
                  >
                    <span class="sr-only">Update</span>
                    <CloudUploadIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    @click.prevent="cancelEditMode(userObj.fullName)"
                    type="button"
                    class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-1 py-1 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-red-400 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-1 focus:ring-teal-500"
                  >
                    <span class="sr-only">Cancel</span>
                    <XIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </span>
              </span>
            </dd>
          </div>
        </dl>
      </div>
    </div>
    <div class="mt-10 divide-y divide-gray-200">
      <div class="space-y-1">
        <h3 class="text-lg font-medium leading-6 text-gray-900">Account</h3>
        <p class="max-w-2xl text-sm text-gray-500">
          Manage how information is displayed on your account.
        </p>
      </div>
      <div class="mt-6">
        <dl class="divide-y divide-gray-100">
          <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt class="text-sm font-medium text-gray-500">Language</dt>
            <dd class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <span class="flex-grow">English</span>
              <span class="ml-4 flex-shrink-0">
                <button
                  type="button"
                  class="text-purple-600 hover:text-purple-500 focus:ring-purple-500 rounded-md bg-white font-medium focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  Update
                </button>
              </span>
            </dd>
          </div>
          <SwitchGroup
            as="div"
            class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5"
          >
            <SwitchLabel
              as="dt"
              class="text-sm font-medium text-gray-500"
              passive
            >
              Automatic timezone
            </SwitchLabel>
            <dd class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <Switch
                v-model="automaticTimezoneEnabled"
                :class="[
                  automaticTimezoneEnabled ? 'bg-teal-600' : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-auto',
                ]"
              >
                <span
                  aria-hidden="true"
                  :class="[
                    automaticTimezoneEnabled
                      ? 'translate-x-5'
                      : 'translate-x-0',
                    'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                  ]"
                />
              </Switch>
            </dd>
          </SwitchGroup>
        </dl>
      </div>
    </div>
  </form>

  <transition
    enter="ease-out duration-300"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="ease-in duration-200"
    leave-from="opacity-100"
    leave-to="opacity-0"
  >
    <changeEmail v-if="showChangeEmail" @closeModal="closeChangeEmailModal" />
  </transition>

  <TransitionRoot as="template" :show="showChangePassword">
    <Dialog
      as="div"
      class="fixed inset-0 z-50 overflow-y-auto"
      @close="showChangePassword = false"
    >
      <div
        class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-teal-600 sm:mx-0 sm:h-10 sm:w-10"
                >
                  <AtSymbolIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <div
                  class="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left"
                >
                  <DialogTitle
                    as="h3"
                    class="text-lg font-medium leading-6 text-gray-900"
                  >
                    Change your password
                  </DialogTitle>
                  <div class="mt-2">
                    <div>
                      <div class="relative mt-4 rounded-md shadow-sm">
                        <!-- <label for="password" class="sr-only">Enter your password</label>
                        <div class="mt-1 relative">
                        <input id="password" name="password" :type="showPasswordField?'password':'text'" autocomplete="password" v-model="signup.password" v-bind:class="{'border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500': v$.signup.password.$errors.length,  'border-gray-300 focus:ring-green-500 focus:border-green-500': !v$.signup.password.$errors.length}" class="appearance-none block w-full px-3 py-2 h-12 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm" placeholder="Enter your password" @input="checkStrength()" />
                        <button  class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 focus:outline-none hover:text-red-500 transition-colors" @click.prevent="showPasswordField=!showPasswordField">
                              <svg v-if="showPasswordField" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                              <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                            </svg>
                            <svg v-if="!showPasswordField" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clip-rule="evenodd" />
                            <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                            </svg>
                          </button>
                        <div v-if="v$.signup.password.$errors.length" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ExclamationCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        </div>

                        <p v-if="!v$.signup.password.$errors.length && passwordScoreFeedback.feedback && passwordScoreFeedback.feedback.warning != ''" class="mt-1 text-xs text-orange-600" id="password-error1">{{ passwordScoreFeedback.feedback.warning }}.</p>
                        <p v-if="v$.signup.password.$errors.length" class="mt-1 text-xs text-red-400" id="password-error">{{ v$.signup.password.$errors[0].$message }}.</p> -->
                      </div>
                      <div class="relative mt-4 rounded-md shadow-sm">
                        <input
                          type="password"
                          name="account-number"
                          class="block h-12 w-full rounded-md border-gray-300 p-2 pr-10 focus:border-teal-600 focus:ring-teal-600 sm:text-sm"
                          placeholder="Your password"
                        />
                        <div
                          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                        >
                          <QuestionMarkCircleIcon
                            class="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
            >
              <button
                @click.prevent="joinCompany"
                type="button"
                class="inline-flex w-full justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Change
              </button>
              <button
                @click.prevent="showChangePassword = false"
                type="button"
                class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                ref="cancelButtonRef"
              >
                Cancel
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import changeEmail from "./modals/changeEmail.vue";
import { ref } from "vue";
import {
  Switch,
  SwitchGroup,
  SwitchLabel,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { QuestionMarkCircleIcon } from "@heroicons/vue/outline";
import {
  PencilIcon,
  XIcon,
  CheckIcon,
  CloudUploadIcon,
  AtSymbolIcon,
} from "@heroicons/vue/solid";

export default {
  name: "UserAppearanceComp",
  components: {
    changeEmail,
    Switch,
    SwitchGroup,
    SwitchLabel,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    PencilIcon,
    XIcon,
    CheckIcon,
    CloudUploadIcon,
    AtSymbolIcon,
    QuestionMarkCircleIcon,
  },
  data() {
    return {
      showChangeEmail: false,
      showChangePassword: false,
      userObj: {
        fullName: {
          value: "",
          editable: false,
        },
      },
    };
  },
  setup() {
    const automaticTimezoneEnabled = ref(true);
    const autoUpdateApplicantDataEnabled = ref(false);
    return {
      automaticTimezoneEnabled,
      autoUpdateApplicantDataEnabled,
    };
  },
  methods: {
    userInitials(userName) {
      const name = userName.split(" ");
      return `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ""}`;
    },
    closeChangeEmailModal() {
      this.showChangeEmail = false;
    },
    goEditMode(model, value) {
      model.editable = true;
      model.value = value;
    },
    cancelEditMode(model) {
      model.editable = false;
    },
  },
};
</script>
