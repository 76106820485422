class GlobalMessageService {
  setLastMessage(message: any) {
    sessionStorage.setItem("lastMessage", JSON.stringify(message));
  }

  getLastMessage() {
    return JSON.parse(sessionStorage.getItem("lastMessage"));
  }

  removeLastMessage() {
    sessionStorage.removeItem("lastMessage");
  }

  parseError(error: any) {
    let msg = "";
    if (
      error &&
      typeof error.response !== "undefined" &&
      typeof error.response.data !== "undefined"
    ) {
      msg = error.response.data.message;
    } else if (typeof error.response !== "undefined") {
      msg = error.response;
    } else if (typeof error.message !== "undefined") {
      msg = error.message;
    } else {
      msg = error.toString();
    }
    return { type: "error", message: msg };
  }
}

export default new GlobalMessageService();
