const {
  createVNode: _createVNode,
  openBlock: _openBlock,
  createBlock: _createBlock,
} = require("vue");

module.exports = function render(_ctx, _cache) {
  return (
    _openBlock(),
    _createBlock(
      "svg",
      {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "none",
        viewBox: "0 0 24 24",
        "stroke-width": "2",
        stroke: "currentColor",
        "aria-hidden": "true",
      },
      [
        _createVNode("path", {
          d: "M7.32824 18.3921c.28342.1699.52595.4134.80938.5833 2.76348 1.6569 6.19528 1.2617 8.51828-.8774m1.8241-3.4919c0-.3399.0818-.6797.0818-1.0196 0-3.3137-2.0454-6.20239-4.9909-7.22195m-4.09057.75991c-.26628.15965-.57099.25019-.83727.40984-2.59636 1.55664-3.89886 4.6419-3.31407 7.6095M12 8.09791c1.3556 0 2.4546-1.14125 2.4546-2.54895S13.3556 3 12 3c-1.3556 0-2.45458 1.14126-2.45458 2.54896 0 1.4077 1.09898 2.54895 2.45458 2.54895ZM5.45458 19.1431c1.35559 0 2.45459-1.1411 2.45459-2.549 0-1.4077-1.099-2.5488-2.45459-2.5488C4.099 14.0453 3 15.1864 3 16.5941c0 1.4079 1.099 2.549 2.45458 2.549Zm13.09082 0c1.3556 0 2.4546-1.1411 2.4546-2.549 0-1.4077-1.099-2.5488-2.4546-2.5488-1.3556 0-2.4546 1.1411-2.4546 2.5488 0 1.4079 1.099 2.549 2.4546 2.549Z",
        }),
      ]
    )
  );
};
