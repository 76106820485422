const {
  createVNode: _createVNode,
  openBlock: _openBlock,
  createBlock: _createBlock,
} = require("vue");

module.exports = function render(_ctx, _cache) {
  return (
    _openBlock(),
    _createBlock(
      "svg",
      {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "none",
        viewBox: "0 0 24 24",
        "stroke-width": "1.5",
        stroke: "currentColor",
        "aria-hidden": "true",
      },
      [
        _createVNode("path", {
          d: "M12.5 18h-1m-4.16667 3h9.33337c.6188 0 1.2123-.2107 1.6499-.5858S19 19.5304 19 19V5c0-.53043-.2458-1.03914-.6834-1.41421C17.879 3.21071 17.2855 3 16.6667 3H7.33333c-.61884 0-1.21233.21071-1.64991.58579C5.24583 3.96086 5 4.46957 5 5v14c0 .5304.24583 1.0391.68342 1.4142C6.121 20.7893 6.71449 21 7.33333 21Z",
        }),
        _createVNode("path", {
          d: "m9.08325 11.5 1.66665 2 4.1667-5",
        }),
      ]
    )
  );
};
