import api from "./api";
import TokenService from "./token.service";

class UserService {
  async getUserDetails() {
    const response: any = await api.get("users");
    if (
      response.data.status == "success" &&
      response.data.statusCode == "200_USER_INFO"
    ) {
      TokenService.setUser(response.data.response);
    }
    return response.data.response;
  }

  async getUserDevices() {
    const response: any = await api.get("user/devices");
    return response.data;
  }

  async getUserMfa() {
    const response: any = await api.get("user/mfa");
    return response.data;
  }
}

export default new UserService();
