class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(sessionStorage.getItem("user"));
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = JSON.parse(sessionStorage.getItem("auth"));
    return user?.session.AccessToken;
  }

  updateLocalAccessToken(token) {
    const user = JSON.parse(sessionStorage.getItem("auth"));
    user.AccessToken = token;
    sessionStorage.setItem("auth", JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(sessionStorage.getItem("auth"));
  }
  setAuth(auth: { message: any; user: any }) {
    sessionStorage.setItem(
      "auth",
      JSON.stringify({ session: auth.message, user: auth.user })
    );
  }

  setUser(user: any) {
    sessionStorage.setItem("user", JSON.stringify(user));
  }

  setVerificationEmail(email: any) {
    sessionStorage.setItem("verificationEmail", JSON.stringify(email));
  }

  getVerificationEmail() {
    return JSON.parse(sessionStorage.getItem("verificationEmail"));
  }

  removeAuth() {
    sessionStorage.removeItem("auth");
  }

  removeUser() {
    sessionStorage.removeItem("user");
  }
}

export default new TokenService();
