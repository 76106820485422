import axios from "axios";

const controller = new AbortController();
const instance = axios.create({
  signal: controller.signal,
  baseURL: "https://dev.api.passwordly.io/apis",
  headers: {
    "Content-Type": "application/json",
  },
});
export default instance;
