<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div class="overflow-hidden sm:rounded-lg">
          <div class="border-b border-gray-200 pb-5">
            <div class="sm:flex sm:items-baseline sm:justify-between">
              <div class="sm:w-0 sm:flex-1">
                <h1
                  id="message-heading"
                  class="text-lg font-medium text-gray-900"
                >
                  Two-Factor Authentication
                </h1>
                <p
                  class="mt-1 overflow-hidden overflow-ellipsis text-sm text-gray-500"
                >
                  Two-Factor Authentication increases the security of your
                  Passwordly acccount.
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="loading"
            class="flex w-full min-w-full items-center justify-center p-1 py-5"
          >
            <div class="text-center">
              <svg
                class="text-teal-600"
                width="57"
                height="57"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                enable-background="new 0 0 0 0"
                xml:space="preserve"
              >
                <path
                  class="opacity-40"
                  fill="currentColor"
                  d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                >
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    dur="1s"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite"
                  />
                </path>
              </svg>
            </div>
          </div>
          <nav v-if="!loading" aria-label="Progress" class="py-4">
            <ol role="list" class="space-y-4 md:flex md:space-y-0 md:space-x-8">
              <li class="md:flex-1">
                <a
                  class="group flex flex-col py-2 pl-4 md:pl-0 md:pt-4 md:pb-0"
                >
                  <span
                    class="text-xs font-semibold tracking-wide text-teal-600 group-hover:text-red-500"
                    >Step 1</span
                  >
                  <span class="text-xs font-medium text-gray-600"
                    >Download Authenticator</span
                  >
                </a>
              </li>
              <li class="md:flex-1">
                <a class="flex flex-col py-2 pl-4 md:pl-0 md:pt-4 md:pb-0">
                  <span
                    class="text-xs font-semibold tracking-wide text-teal-600"
                    >Step 2</span
                  >
                  <span class="text-xs font-medium text-gray-600"
                    >Scan QR Code</span
                  >
                </a>
              </li>
              <li class="md:flex-1">
                <a
                  class="group flex flex-col py-2 pl-4 md:pl-0 md:pt-4 md:pb-0"
                >
                  <span
                    class="text-xs font-semibold tracking-wide text-teal-600 group-hover:text-gray-700"
                    >Step 3</span
                  >
                  <span class="text-xs font-medium text-gray-600"
                    >Verify 6-digit code</span
                  >
                </a>
              </li>
            </ol>
          </nav>
          <div v-if="!loading" class="grid grid-cols-3 gap-8 p-1">
            <div>
              <ul role="list" class="">
                <li v-for="totp in totpApps" :key="totp.id" class="py-2">
                  <div
                    class="relative focus-within:ring-2 focus-within:ring-teal-600"
                  >
                    <h3 class="text-sm font-semibold text-gray-600">
                      <a
                        :href="totp.url"
                        target="_blank"
                        class="items-center hover:underline focus:outline-none"
                      >
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        {{ totp.title }}
                        <ExternalLinkIcon
                          class="h-5 w-5 text-gray-600"
                          aria-hidden="true"
                        />
                      </a>
                    </h3>
                  </div>
                </li>
              </ul>
              <p
                class="mt-1 overflow-hidden overflow-ellipsis text-sm text-gray-500"
              >
                Or any other TOTP compatible application.
              </p>
            </div>
            <div>
              <div class="flex">
                <img
                  v-if="!loading"
                  class="h-full w-full border border-gray-200 p-6 text-gray-300 sm:w-full"
                  :src="totp.image"
                />
              </div>
              <div v-if="!loading" class="relative">
                <div
                  class="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div class="w-full border-t border-gray-200" />
                </div>
                <div class="relative flex justify-center">
                  <span class="bg-white px-2 text-sm text-gray-500">
                    Or copy secret
                  </span>
                </div>
              </div>
              <div
                v-if="!loading"
                class="mt-1 flex flex-wrap rounded-md shadow-sm"
              >
                <span
                  class="flex items-center break-all rounded-md border border-gray-300 bg-gray-50 px-3 py-1 text-gray-500 sm:text-xs"
                >
                  {{ totp.secret }}
                </span>
              </div>
            </div>
            <div>
              <form @submit.prevent="onSubmit">
                <div
                  class="grid grid-flow-col grid-cols-6 grid-rows-1 gap-2 p-1"
                >
                  <div v-for="(v, index) in values" class="mt-1" :key="index">
                    <input
                      type="tel"
                      v-bind:class="{
                        'border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500':
                          v$.verify['code' + index].$errors.length,
                        'border-gray-300 focus:ring-green-500 focus:border-green-500':
                          !v$.verify['code' + index].$errors.length,
                      }"
                      class="block h-10 w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-center font-semibold placeholder-gray-400 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                      :data-id="index"
                      :autoFocus="0"
                      @input="focusNext($event.target)"
                      @keyup.delete="focusPrev($event.target)"
                      @focus="verify['code' + index] = ''"
                      v-model="verify['code' + index]"
                      maxlength="1"
                    />
                  </div>
                </div>
              </form>
              <div class="py-2">
                <button
                  type="submit"
                  class="flex w-full justify-center rounded-md border border-transparent bg-teal-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2 disabled:opacity-50"
                  :disabled="loading"
                >
                  <svg
                    v-show="formLoading"
                    class="... mr-3 h-5 w-5 animate-spin"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span v-if="!formLoading">Verify</span>
                  <span v-if="formLoading">Processing...</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/user.service";
import useVuelidate from "@vuelidate/core";
import { required, numeric, minLength, maxLength } from "@vuelidate/validators";
import {
  //   DesktopComputerIcon,
  //   DeviceMobileIcon,
  //   ShieldCheckIcon,
  //   TrashIcon,
  //   CheckIcon
  ExternalLinkIcon,
} from "@heroicons/vue/outline";

const totpApps = [
  {
    id: 1,
    title: "Google Authenticator",
    url: "https://support.google.com/accounts/bin/answer.py?hl=en&answer=1066447",
  },
  {
    id: 2,
    title: "Microsoft Authenticator",
    url: "https://www.microsoft.com/en-us/account/authenticator",
  },
  {
    id: 3,
    title: "Authy",
    url: "https://authy.com",
  },
];

export default {
  name: "UserSecurityComp",
  components: {
    // ShieldCheckIcon,
    // DesktopComputerIcon,
    // DeviceMobileIcon,
    // TrashIcon,
    //CheckIcon
    ExternalLinkIcon,
  },
  setup() {
    return { totpApps, v$: useVuelidate() };
  },
  data() {
    return {
      totp: "",
      values: 6,
      loading: true,
      formLoading: false,
      validated: 0,
      accountVerified: 0,
      emailToBeVerified: "",
      verify: {
        code0: "",
        code1: "",
        code2: "",
        code3: "",
        code4: "",
        code5: "",
      },
      message: "",
    };
  },
  validations() {
    const verifyRules = {
      verify: {
        code0: {
          required,
          numeric,
          minLength: minLength(1),
          maxLength: maxLength(1),
        },
        code1: {
          required,
          numeric,
          minLength: minLength(1),
          maxLength: maxLength(1),
        },
        code2: {
          required,
          numeric,
          minLength: minLength(1),
          maxLength: maxLength(1),
        },
        code3: {
          required,
          numeric,
          minLength: minLength(1),
          maxLength: maxLength(1),
        },
        code4: {
          required,
          numeric,
          minLength: minLength(1),
          maxLength: maxLength(1),
        },
        code5: {
          required,
          numeric,
          minLength: minLength(1),
          maxLength: maxLength(1),
        },
      },
    };
    return verifyRules;
  },
  mounted() {
    UserService.getUserMfa().then(
      (response) => {
        this.totp = response;
        this.loading = false;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    onFocus(e) {
      e.target.select(e);
    },
    focusPrev(elem) {
      const currentIndex = Array.from(elem.form.elements).indexOf(elem);
      if (currentIndex == 0) {
        return;
      }
      elem.form.elements
        .item(
          currentIndex < elem.form.elements.length - 1 ? currentIndex - 1 : 0
        )
        .focus();
    },
    async focusNext(elem) {
      console.log(elem);
      const currentIndex = Array.from(elem.form.elements).indexOf(elem);

      if (currentIndex > 3) {
        this.validated = await this.v$.$validate();
        this.loading = true;

        if (this.validated) {
          return this.initiate(this.verify);
        }
        this.loading = false;
      }

      if (currentIndex === 5) {
        return;
      }
      elem.form.elements
        .item(
          currentIndex < elem.form.elements.length - 1 ? currentIndex + 1 : 0
        )
        .focus();
    },
    async onSubmit() {
      this.validated = await this.v$.$validate();
      this.loading = true;

      if (this.validated) {
        return this.initiate(this.verify);
      }

      this.loading = false;
    },
  },
};
</script>
