<template>
  <div>
    <TransitionRoot as="template" :show="shouldShowModal">
      <Dialog
        as="div"
        class="fixed inset-0 z-50 overflow-y-auto"
        @close="shouldShowChangeEmailModal = false"
      >
        <div
          class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 bg-passwordlyGray-500 bg-opacity-75 transition-opacity"
            />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle"
            >
              <div class="px-4 py-3 sm:flex sm:px-6 border-b border-gray-200">
                <DialogTitle
                  as="h3"
                  class="text-md font-medium leading-6 text-gray-900"
                >
                  Change your email address
                </DialogTitle>
              </div>

              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mt-3 w-full text-center sm:mt-0 sm:text-left">
                    <div class="mt-4">
                      <div>
                        <div>
                          <div class="mt-1 flex rounded-md shadow-sm">
                            <div
                              class="relative flex flex-grow items-stretch focus-within:z-10"
                            >
                              <input
                                type="text"
                                name="newEmail"
                                id="newEmail"
                                v-model="changeEmailObj.newEmail"
                                class="block h-12 w-full rounded-none rounded-l-md border-gray-300 focus:border-passwordlyGreen-500 focus:ring-passwordlyGreen-500 sm:text-sm"
                                placeholder="Enter new email address"
                              />
                            </div>

                            <button
                              v-tippy="{
                                content: 'Send verification code',
                                placement: 'top',
                                theme: 'material',
                                arrow: false,
                              }"
                              type="button"
                              class="relative -ml-px inline-flex h-12 items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-semibold text-passwordlyGreen-500 hover:bg-gray-100 focus:border-passwordlyGreen-500 focus:outline-none focus:ring-1 focus:ring-passwordlyGreen-500"
                            >
                              Verify
                            </button>
                          </div>
                        </div>
                        <div class="relative mt-4 rounded-md shadow-sm">
                          <input
                            type="text"
                            name="verificationCode"
                            v-model="changeEmailObj.verificationCode"
                            class="block h-12 w-full rounded-md border-gray-300 p-2 pr-10 focus:border-passwordlyGreen-500 focus:ring-passwordlyGreen-500 sm:text-sm"
                            placeholder="Verification code"
                          />
                          <div
                            class="absolute inset-y-0 right-0 flex items-center pr-3"
                          >
                            <QuestionMarkCircleIcon
                              v-tippy="{
                                content:
                                  'Enter verification code from your new email',
                                placement: 'top',
                                theme: 'material',
                                arrow: false,
                              }"
                              class="h-4 w-4 text-passwordlyGreen-400"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                        <div class="relative mt-4 rounded-md shadow-sm">
                          <input
                            type="password"
                            name="verifyPassword"
                            v-model="changeEmailObj.verifyPassword"
                            class="block h-12 w-full rounded-md border-gray-300 p-2 pr-10 focus:border-passwordlyGreen-500 focus:ring-passwordlyGreen-500 sm:text-sm"
                            placeholder="Your password"
                          />
                          <div
                            class="absolute inset-y-0 right-0 flex items-center pr-3"
                          >
                            <QuestionMarkCircleIcon
                              v-tippy="{
                                content: 'Enter your password',
                                placement: 'top',
                                theme: 'material',
                                arrow: false,
                              }"
                              class="h-4 w-4 text-passwordlyGreen-400"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="bg-gray-50 border-t border-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
              >
                <button
                  @click.prevent="joinCompany"
                  type="button"
                  class="inline-flex w-full justify-center rounded-md border border-transparent bg-passwordlyGreen-500 px-4 py-1 text-base font-medium text-white shadow-sm hover:bg-passwordlyGreen-500 focus:outline-none focus:ring-2 focus:ring-passwordlyGreen-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Change email
                </button>
                <button
                  @click.prevent="emitCloseModal"
                  type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-1 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-passwordlyGreen-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                  ref="cancelButtonRef"
                >
                  Cancel
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { QuestionMarkCircleIcon } from "@heroicons/vue/outline";
import {} from "@heroicons/vue/solid";

export default {
  name: "ChangeEmailComp",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    QuestionMarkCircleIcon,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      changeEmailObj: {
        newEmail: "",
        verificationCode: "",
        verifyPassword: "",
      },
    };
  },
  setup() {
    return {};
  },
  computed: {
    shouldShowModal() {
      return this.showModal;
    },
  },
  methods: {
    emitCloseModal() {
      this.$emit("closeModal", true);
    },
  },
};
</script>
