<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div class="overflow-hidden sm:rounded-lg">
          <div class="border-b border-gray-200 pb-5">
            <div class="sm:flex sm:items-baseline sm:justify-between">
              <div class="ml-4 mt-4">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Devices
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  Devices where you're logged into Passwordly.
                </p>
              </div>
              <div class="ml-4 mt-4 mr-1 flex-shrink-0">
                <button
                  type="button"
                  class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-xs font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
                >
                  Sign out all other sessions
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="loading"
            class="flex w-full min-w-full items-center justify-center py-10"
          >
            <div class="text-center">
              <svg
                class="text-teal-600"
                width="57"
                height="57"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                enable-background="new 0 0 0 0"
                xml:space="preserve"
              >
                <path
                  class="opacity-50"
                  fill="currentColor"
                  d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                >
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    dur="1s"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite"
                  />
                </path>
              </svg>
            </div>
          </div>

          <table v-if="!loading" class="min-w-full divide-y divide-gray-200">
            <thead class="">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Device
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Location
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Last Seen
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Status
                </th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody class="relative divide-y divide-gray-200">
              <div v-if="!loading && !devices.length">
                <td class="whitespace-nowrap px-6 py-4">No devices found</td>
              </div>

              <tr class="relative" v-for="device in devices" :key="device.id">
                <div
                  v-if="device.markedForDeletion"
                  class="sm:flex sm:items-baseline sm:justify-end bg-white w-full h-full absolute top-0"
                >
                  <div class="ml-4 mt-4">
                    <p class="mt-1 text-sm text-gray-500">
                      Are you sure you want to delete this device?
                    </p>
                  </div>
                  <div class="ml-4 mt-4 mr-1 flex-shrink-0">
                    <button
                      @click.prevent="markDeviceForDeletion(device, 'remove')"
                      type="button"
                      class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-xs font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                    <button
                      @click.prevent="markDeviceForDeletion(device, 'add')"
                      v-tippy="{ content: 'Delete device', theme: 'material' }"
                      type="button"
                      class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-xs font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
                    >
                      <TrashIcon
                        class="h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                      Delete
                    </button>
                  </div>
                </div>
                <td class="whitespace-nowrap px-6 py-4">
                  <div class="flex items-center">
                    <div class="h-5 w-5 flex-shrink-0">
                      <component
                        :is="device.icon"
                        class="-mt-0.5 h-6 w-6 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <DesktopComputerIcon
                        v-if="!device.deviceData.device.Mobile"
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <DeviceMobileIcon
                        v-if="device.deviceData.device.Mobile"
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div class="ml-4">
                      <div
                        v-if="!device.deviceData.device.Mobile"
                        class="text-sm font-medium text-gray-900"
                      >
                        {{ device.deviceData.device.browser.name }}
                      </div>
                      <div class="text-sm text-gray-500">
                        {{ device.deviceData.device.os }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="whitespace-nowrap px-6 py-4">
                  <div class="text-sm text-gray-900">
                    {{ device.deviceData.location.country }}
                  </div>
                  <div
                    v-if="device.deviceData.location.city === ''"
                    class="text-sm text-gray-500"
                  >
                    {{ device.deviceData.location.subDivision }}
                  </div>
                  <div
                    v-if="device.deviceData.location.city != ''"
                    class="text-sm text-gray-500"
                  >
                    {{ device.deviceData.location.city }}
                  </div>
                  <div
                    v-if="device.deviceData.location.ipAddr != ''"
                    class="text-xs text-gray-400"
                  >
                    IP: {{ device.deviceData.location.ipAddr }}
                  </div>
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{ dateToHumanReadable(device.lastSeen) }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  <div class="flex items-center justify-center">
                    <ShieldExclamationIcon
                      v-tippy="{
                        content: 'Device not trusted',
                        theme: 'material',
                      }"
                      v-if="!device.isTrusted"
                      class="h-5 w-5 text-orange-400"
                      aria-hidden="true"
                    />

                    <ShieldCheckIcon
                      v-tippy="{ content: 'Device trusted', theme: 'material' }"
                      v-if="device.isTrusted"
                      class="h-5 w-5 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
                >
                  <button
                    v-tippy="{
                      content: 'Trust this device for 30 days',
                      theme: 'material',
                    }"
                    type="button"
                    class="inline-flex items-center rounded-md border border-gray-200 bg-white py-1 px-1 text-sm font-medium shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
                  >
                    <ShieldCheckIcon
                      class="h-5 w-5 text-teal-500"
                      aria-hidden="true"
                    />
                  </button>

                  <button
                    @click.prevent="markDeviceForDeletion(device, 'add')"
                    v-tippy="{
                      content: 'Delete this device',
                      theme: 'material',
                      font: '12',
                    }"
                    type="button"
                    class="ml-3 inline-flex items-center rounded-md border border-gray-200 bg-white py-1 px-1 text-sm font-medium shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
                  >
                    <TrashIcon
                      class="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/user.service";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

import {
  DesktopComputerIcon,
  DeviceMobileIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
  TrashIcon,
  CheckIcon,
} from "@heroicons/vue/outline";

export default {
  name: "UserDevicesComp",
  components: {
    ShieldCheckIcon,
    ShieldExclamationIcon,
    DesktopComputerIcon,
    DeviceMobileIcon,
    TrashIcon,
    CheckIcon,
  },
  setup() {
    return { dayjs };
  },
  data() {
    return {
      devices: [],
      deviceToBeDeleted: "",
      loading: true,
      message: "",
    };
  },
  mounted() {
    UserService.getUserDevices().then(
      (response) => {
        this.devices = response.devices;
        this.loading = false;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    markDeviceForDeletion(device, action) {
      if (action === "add") {
        device.markedForDeletion = true;
        this.deviceToBeDeleted = device.id;
      } else {
        device.markedForDeletion = false;
        this.deviceToBeDeleted = "";
      }
    },
    dateToHumanReadable(value) {
      return dayjs(value).fromNow();
    },
  },
};
</script>
