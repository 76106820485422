import AuthService from "@/services/auth.service";

const authData = JSON.parse(sessionStorage.getItem("auth"));
const initialState = authData
  ? { authStatus: { loggedIn: true }, authData }
  : { authStatus: { loggedIn: false }, auth: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, auth) {
      let service: any;
      if (auth.step == 1) {
        service = AuthService.loginStepOne(auth);
      } else if (auth.step == 2) {
        service = AuthService.loginStepTwo(auth);
      } else if (auth.step == 3) {
        service = AuthService.loginStepThree(auth);
      }

      return service.then(
        (auth) => {
          commit("loginSuccess", auth);
          return Promise.resolve(auth);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },

    activate({ commit }, auth) {
      return AuthService.activate(auth).then(
        (auth) => {
          commit("activateSuccess", auth);
          return Promise.resolve(auth);
        },
        (error) => {
          commit("activateFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    register({ commit }, auth) {
      return AuthService.register(auth).then(
        (response) => {
          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    loginSuccess(state, auth) {
      state.authStatus.loggedIn = true;
      state.auth = auth;
    },
    loginFailure(state) {
      state.authStatus.loggedIn = false;
      state.auth = null;
    },
    logout(state) {
      state.authStatus.loggedIn = false;
      state.auth = null;
    },
    registerSuccess(state) {
      state.authStatus.loggedIn = false;
    },
    registerFailure(state) {
      state.authStatus.loggedIn = false;
    },
    activateSuccess(state) {
      state.authStatus.loggedIn = false;
      //state.auth = null;
    },
    activateFailure(state) {
      state.authStatus.loggedIn = false;
      //state.auth = null;
    },
  },
};
