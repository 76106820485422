const {
  createVNode: _createVNode,
  openBlock: _openBlock,
  createBlock: _createBlock,
} = require("vue");

module.exports = function render(_ctx, _cache) {
  return (
    _openBlock(),
    _createBlock(
      "svg",
      {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "none",
        viewBox: "0 0 24 24",
        "stroke-width": "2",
        stroke: "currentColor",
        "aria-hidden": "true",
      },
      [
        _createVNode("path", {
          stroke: "none",
          d: "M0 0h24v24H0z",
        }),
        _createVNode("rect", {
          x: "3",
          y: "3",
          width: "6",
          height: "6",
          rx: "1",
        }),
        _createVNode("rect", {
          x: "15",
          y: "15",
          width: "6",
          height: "6",
          rx: "1",
        }),
        _createVNode("path", {
          d: "M21 11v-3a2 2 0 0 0 -2 -2h-6l3 3m0 -6l-3 3",
        }),
        _createVNode("path", {
          d: "M3 13v3a2 2 0 0 0 2 2h6l-3 -3m0 6l3 -3",
        }),
      ]
    )
  );
};
