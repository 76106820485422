const {
  createVNode: _createVNode,
  openBlock: _openBlock,
  createBlock: _createBlock,
} = require("vue");

module.exports = function render(_ctx, _cache) {
  return (
    _openBlock(),
    _createBlock(
      "svg",
      {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "none",
        viewBox: "0 0 24 24",
        "stroke-width": "2",
        stroke: "currentColor",
        "aria-hidden": "true",
      },
      [
        _createVNode("path", {
          d: "M14 2H6c-.53043 0-1.03914.21071-1.41421.58579C4.21071 2.96086 4 3.46957 4 4v16c0 .5304.21071 1.0391.58579 1.4142C4.96086 21.7893 5.46957 22 6 22h12c.5304 0 1.0391-.2107 1.4142-.5858S20 20.5304 20 20V8l-6-6Z",
        }),
        _createVNode("path", {
          d: "M14 2v6h6m-8 10v-6m-3 3h6",
        }),
      ]
    )
  );
};
