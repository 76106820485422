const {
  createVNode: _createVNode,
  openBlock: _openBlock,
  createBlock: _createBlock,
} = require("vue");

module.exports = function render(_ctx, _cache) {
  return (
    _openBlock(),
    _createBlock(
      "svg",
      {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "none",
        viewBox: "0 0 24 24",
        "stroke-width": "2",
        stroke: "currentColor",
        "aria-hidden": "true",
      },
      [
        _createVNode("path", {
          d: "M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2",
        }),
        _createVNode("circle", { cx: "8.5", cy: "7", r: "4" }),
        _createVNode("polyline", { points: "17 11 19 13 23 9" }),
      ]
    )
  );
};
