import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

// lazy-loaded
const Dashboard = () => import("../components/Dashboard.vue");

import PageNotFound from "../components/PageNotFound.vue";

// auth
import Login from "../components/auth/Login.vue";
//const Login = () => import("../components/auth/Login.vue");
import Register from "../components/auth/Register.vue";
//const Register = () => import("../components/auth/Register.vue");
//import VerifyAccount from "../components/auth/VerifyAccount.vue";
const VerifyAccount = () => import("../components/auth/VerifyAccount.vue");

// company
//import Company from "../components/companies/CompanySettings.vue";
const Company = () => import("../components/companies/CompanySettings.vue");
//import newCompany from "../components/companies/newCompany.vue";
const newCompany = () => import("../components/companies/newCompany.vue");
import companyDetails from "../components/companies/settings/details.vue";
import companyRoles from "../components/companies/settings/roles.vue";
import companyGroups from "../components/companies/settings/groups.vue";

// account
const Account = () => import("../components/user/Account.vue");
import AccountDetails from "../components/user/profile/details.vue";
import AccountSecurity from "../components/user/profile/security.vue";
import AccountDevices from "../components/user/profile/devices.vue";
import AccountNotifications from "../components/user/profile/notifications.vue";
import AccountAppearance from "../components/user/profile/appearance.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Dashboard,
  },
  {
    path: "/dashboard",
    name: "dashboardPage",
    component: Dashboard,
  },
  {
    path: "/login",
    name: "authPage",
    component: Login,
  },
  {
    path: "/logout",
    name: "logoutPage",
    component: Login,
  },
  {
    path: "/signup",
    component: Register,
  },
  {
    path: "/signup/verify",
    name: "verifyAccount",
    component: VerifyAccount,
  },
  {
    path: "/account",
    name: "account",
    // lazy-loaded
    component: Account,
    children: [
      { path: "details", component: AccountDetails },
      { path: "security", component: AccountSecurity },
      { path: "devices", component: AccountDevices },
      { path: "notifications", component: AccountNotifications },
      { path: "appearance", component: AccountAppearance },
    ],
  },
  { path: "/company/new", name: "newCompany", component: newCompany },
  {
    path: "/company",
    name: "company",
    // lazy-loaded
    component: Company,
    children: [
      { path: "details", name: "companyDetails", component: companyDetails },
      { path: "roles", name: "companyRoles", component: companyRoles },
      { path: "groups", name: "companyGroups", component: companyGroups },
      { path: "users", name: "companyUsers", component: companyDetails },
      {
        path: "integrations",
        name: "companyIntegrations",
        component: companyDetails,
      },
    ],
  },
  { path: "/:pathMatch(.*)*", component: PageNotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/signup", "/signup/verify"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = sessionStorage.getItem("auth");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) next({ name: "authPage" });
  else next();
});

export default router;
