const {
  createVNode: _createVNode,
  openBlock: _openBlock,
  createBlock: _createBlock,
} = require("vue");

module.exports = function render(_ctx, _cache) {
  return (
    _openBlock(),
    _createBlock(
      "svg",
      {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "none",
        viewBox: "0 0 24 24",
        "stroke-width": "1.5",
        stroke: "currentColor",
        "aria-hidden": "true",
      },
      [
        _createVNode("path", {
          d: "M20 8h-6c-.5523 0-1 .44772-1 1v10c0 .5523.4477 1 1 1h6c.5523 0 1-.4477 1-1V9c0-.55228-.4477-1-1-1Z",
        }),
        _createVNode("path", {
          d: "M18 8V5c0-.26522-.1054-.51957-.2929-.70711C17.5196 4.10536 17.2652 4 17 4H4c-.26522 0-.51957.10536-.70711.29289C3.10536 4.48043 3 4.73478 3 5v12c0 .2652.10536.5196.29289.7071C3.48043 17.8946 3.73478 18 4 18h9m3-9h2",
        }),
      ]
    )
  );
};
