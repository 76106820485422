import api from "./api";
import TokenService from "./token.service";
import EncryptionService from "./cryptography/encryption.service";

import { Client as srp, computeVerifier, bufferToHex, params } from "./srp/srp";
import { toSha256 } from "./cryptography/hashing";
import { createFingerPrint } from "./fingerprint/index";
import Router from "@/router";

class AuthService {
  async loginStepOne(user) {
    const response: any = await api.post("auth", {
      action: "signin",
      email: user.email,
      step: user.step,
    });
    return response.data;
  }
  async loginStepTwo(user) {
    const key = crypto.getRandomValues(new Uint8Array(32));
    const client = new srp(
      params["4096"],
      user.email,
      user.password,
      user.server.salt,
      key
    );
    user.client.A = bufferToHex(client.computeA());

    const serverB = new Uint8Array(
      user.server.B.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
    client.setB(serverB);
    user.client.M1 = bufferToHex(client.computeM1());

    const response: any = await api.post("auth", {
      action: "signin",
      sessionId: user.sessionId,
      A: user.client.A,
      M1: user.client.M1,
      step: user.step,
    });
    if (
      response.data.status == "success" &&
      response.data.statusCode == "200_SIGNIN_TWO"
    ) {
      user.server.m2 = new Uint8Array(
        response.data.m2.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
      );
      const isM2valid = client.checkM2(user.server.m2);
      if (isM2valid) {
        user.client.K = bufferToHex(client.computeK());
      }
    }
    return response.data;
  }
  async loginStepThree(user) {
    const payload: string = await toSha256(user.client.K);
    const fingerprint = createFingerPrint(user.email);
    const browser = await fingerprint.get();

    const response: any = await api.post("auth", {
      action: "signin",
      sessionId: user.sessionId,
      payload: payload.toString(),
      step: 3,
      d: browser,
    });

    if (
      response.data.status == "success" &&
      response.data.statusCode == "200_SIGNIN_THREE"
    ) {
      if (response.data.message.AccessToken) {
        TokenService.setAuth(response.data);
        //Router.push("/");
      }
    }
    return response.data;
  }

  async activate(user) {
    const code =
      user.code0 +
      user.code1 +
      user.code2 +
      user.code3 +
      user.code4 +
      user.code5;
    const response = await api.post("auth", {
      action: "activate",
      email: user.email,
      code: code,
    });

    return response.data;
  }

  logout() {
    TokenService.removeAuth();
    TokenService.removeUser();
    Router.push("/login");
  }

  async register(user: { email: string; password: string; fullName: string }) {
    const salt: string = bufferToHex(
      crypto.getRandomValues(new Uint8Array(32))
    );
    const verifier: Uint8Array = computeVerifier(
      params["4096"],
      user.email,
      user.password,
      salt
    );

    const userEncryptionKey = await EncryptionService.generateEncryptionKey();
    const encryptedUserKey = await EncryptionService.encrypt(
      user.password,
      userEncryptionKey,
      "rest"
    );
    return api.put("auth", {
      action: "signup",
      name: user.fullName,
      email: user.email,
      salt: btoa(salt),
      verifier: btoa(EncryptionService.buf2hex(verifier)),
      key: {
        type: "user",
        key: btoa(encryptedUserKey),
      },
    });
  }
}

export default new AuthService();
