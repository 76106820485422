import axiosInstance from "./api";
import TokenService from "./token.service";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
        config.headers["AuthorizationToken"] = "Bearer " + token;
      }
      if (config.url && config.url != "auth" && !token) {
        console.log("should reject request");
        //config.headers["shouldCancel"] = true;
        config.url = null;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "auth" && err.response) {
        // Access Token was expired
        // if (err.response.status === 401 && !originalConfig._retry) {
        //   originalConfig._retry = true;
        //   try {
        //     // const rs = await axiosInstance.post("/auth/refreshtoken", {
        //     //   refreshToken: TokenService.getLocalRefreshToken(),
        //     // });
        //     // const { accessToken } = rs.data;
        //     store.dispatch("auth/logout");
        //     //TokenService.updateLocalAccessToken(accessToken);
        //     return axiosInstance(originalConfig);
        //   } catch (_error) {
        //     return Promise.reject(_error);
        //   }
        // }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
